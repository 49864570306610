import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { UserInteractionVariant } from '@naf/user-interaction';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { CenterArticleType } from '../../../../../types/centerArticleType';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { useDocument } from '../../../hooks/useDocument';
import S from './styles';
import { LoaderPage } from '../../LoaderPage';
import BlockContent from '../../../components/block-content/BlockContent';
import { CenterInformation } from './components/CenterInformation';
import { CenterProductArticleType } from '../../../../../types/centerProductArticleType';
import { DocumentTypeLabel } from '../../../../../types/testType';
import { ArticleType } from '../../../../../types/articleType';
import CenterInformationTable from '../../../components/tables/CenterInformationTable';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import SchemaCenter from '../../../components/structured-data/SchemaCenter';
import { nafCenterBasePath } from '../../../routers/NafCenterRouter';
import ResponsiveImage from '../../../components/image/ResponsiveImage';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { NafCenterProduct } from './components/NafCenterProduct';
import { NafCenterOpenHours } from './components/NafCenterOpenHours';
import { NafCenterTag } from './components/NafCenterTag';
import { NafCenterAddress } from './components/NafCenterAddress';
import { NotFound } from '../../../loadable-elements/NotFound';

interface Props {
  centerSlug: string;
}

export const NafCenterArticle = ({ centerSlug }: Props) => {
  const { simpleToken } = useAuth0Token();
  const { meta, data } = useDocument(centerSlug, simpleToken);
  const [showAllProducts, setShowAllProducts] = useState<boolean>(false);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (
      data &&
      centerSlug &&
      history &&
      ((data as CenterProductArticleType).type === DocumentTypeLabel.nafCenterProductArticle ||
        ((data as ArticleType).category && (data as ArticleType).category?.name === 'NAF Senter'))
    ) {
      // If data contains a NAF Center document, redirect to article page
      history.push(`/${nafCenterBasePath}/${centerSlug}`);
    }
  }, [data, centerSlug, history]);

  const centerArticleData = data as CenterArticleType;

  const {
    address,
    body,
    city,
    closingTime,
    closingTimeFriday,
    coordinates,
    image,
    ingress,
    openingTime,
    openingTimeFriday,
    products,
    seoConfig,
    tags,
    title,
    additionalInformation,
    informationText,
    informationTable,
  } = centerArticleData || {};
  const informationTableRef = useRef<HTMLDivElement>(null);

  const cldImage = useResponsiveImage({ imageId: image?.publicId, initialDimensions: { width: 1200, height: 350 } });
  const seoTitle = seoConfig?.title ? seoConfig.title : title;
  const schemaImage = cldImage && cldImage.toURL();
  const windowPath = useWindowLocation();

  // Check if document matches CenterArticleType
  if (centerArticleData && centerArticleData.centerId) {
    return (
      <LayoutWithMainContent
        title={title}
        description={ingress || seoConfig?.introduction || ''}
        imgUrl={schemaImage || ''}
        url={windowPath}
        gtmArgs={{
          page_type: 'NAFCenterArticle',
          pageCategory: 'NAFCenterArticle',
          contentId: centerSlug,
        }}
        isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
        seoTitle={seoTitle}
        seoDescription={seoConfig?.introduction || undefined}
        canonicalUrl={windowPath}
        internalSearchMetaData={{
          cardType: InternalSearchCardType.NAFCenter,
          imagePublicId: image?.publicId,
          streetAddress: address,
          postalCode: city,
        }}
      >
        <SchemaCenter
          title={title}
          openingTime={openingTime}
          openingTimeFriday={openingTimeFriday}
          closingTime={closingTime}
          closingTimeFriday={closingTimeFriday}
          address={address}
          city={city}
          coordinates={coordinates}
        />
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            {image && (
              <S.ImageColWrapper ref={imageWrapperRef}>
                <ResponsiveImage imageId={image?.publicId} altText={image.altText || image.caption} />
              </S.ImageColWrapper>
            )}
          </GridCol>
          <S.PageCol s="12" m="12" l="8" xl="8" $hasImage={!!image}>
            <Text variant={TextVariant.Header1}>{title}</Text>
            {tags && (
              <S.TagsRow>
                {tags.map((tag) => (
                  <NafCenterTag key={tag.description} description={tag.description} icon={tag.icon} />
                ))}
              </S.TagsRow>
            )}
            {informationText && (
              <S.InfoBox title="" variant={UserInteractionVariant.Info}>
                {informationText}
              </S.InfoBox>
            )}
            {(address || (openingTime && openingTimeFriday && closingTime && closingTimeFriday)) && (
              <S.AddressRow>
                {address && <NafCenterAddress address={address} city={city} coordinates={coordinates} />}
                {openingTime && openingTimeFriday && closingTime && closingTimeFriday && (
                  <NafCenterOpenHours
                    closingTime={closingTime}
                    closingTimeFriday={closingTimeFriday}
                    openingTime={openingTime}
                    openingTimeFriday={openingTimeFriday}
                    informationTable={informationTable}
                  />
                )}
              </S.AddressRow>
            )}
            {products && products.length > 0 && (
              <S.Products>
                <Text variant={TextVariant.Header2}>Vi tilbyr</Text>
                <div>
                  {products.map((product, index) => {
                    if (showAllProducts || index < 3)
                      return (
                        <NafCenterProduct
                          key={product.productId}
                          name={product.name}
                          ingress={product.ingress}
                          memberPrice={product.memberPrice}
                          nonMemberPrice={product.nonMemberPrice}
                          productId={product.productId}
                          displayStyle={product.displayStyle}
                          internalProductId={product.internalProductId}
                          booking={product.booking}
                          bookingText={product.bookingText}
                          isBeta={product.isBeta}
                          isNew={product.isNew}
                          isForMembersOnly={product.isForMembersOnly}
                          link={product.link}
                          linkText={product.linkText}
                        />
                      );
                    return null;
                  })}
                </div>
                {!showAllProducts && products && products.length >= 3 && (
                  <S.ShowAllWrapper>
                    <Button variant="outline" onClick={() => setShowAllProducts(true)}>
                      Se alle
                    </Button>
                    <Text variant={TextVariant.BodyText}>Viser 3 av 10</Text>
                  </S.ShowAllWrapper>
                )}
              </S.Products>
            )}
            {body && body.length > 0 && (
              <S.Body>
                {ingress && <Text>{ingress}</Text>}
                {additionalInformation && additionalInformation.length > 0 && (
                  <CenterInformation title={title} additionalInformation={additionalInformation} />
                )}
                <BlockContent value={body} />
              </S.Body>
            )}
            {informationTable && (
              <div ref={informationTableRef}>
                <CenterInformationTable table={informationTable.table} />
              </div>
            )}
          </S.PageCol>
        </Grid>
      </LayoutWithMainContent>
    );
  }
  // If the document doesnt match CenterArticleType, render 404 page
  if (data && !(data as CenterArticleType).centerId) {
    return <NotFound />;
  }
  if (!data && meta.isUpdating) {
    return <LoaderPage />;
  }
  if (!data && !meta.isUpdating) {
    return <NotFound />;
  }
  return null;
};
